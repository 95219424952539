.top {
  display: flex;
  justify-content: space-between;
}
.top_element {
  font-size: 40px;
  padding-left: 15px;
  padding-bottom: 5px;
}
.slider :global(.slick-track) {
  display: flex;
  gap: 15px;
}
.slider :global(.card) {
  min-height: 200px;
}

.slider_component {
  margin-bottom: 50px;
}
